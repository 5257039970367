import tokens from './tokens'

//NOTE ALL ADRESSES NEED TO BE CHECKSUMMED
const farms = [
  {
    pid: 0,
    plsxPid: 1,
    lpSymbol: 'eDAI-WPLS',
    lpAddress:'0xE56043671df55dE5CDf8459710433C10324DE0aE',
    token: tokens.dai,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    native: false
  },
  {
    pid: 1,
    plsxPid: 2,
    lpSymbol: 'eUSDC-WPLS',
    lpAddress:'0x6753560538ECa67617A9Ce605178F788bE7E524E',
    token: tokens.eusdc,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    native: false
  },
  {
    pid: 2,
    plsxPid: 0,
    lpSymbol: 'PLSX-WPLS',
    lpAddress:'0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9',
    token: tokens.plsx,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    native: false
  },
  {
    pid: 3,
    plsxPid: 4,
    lpSymbol: 'WETH-WPLS',
    lpAddress:'0x42AbdFDB63f3282033C766E72Cc4810738571609',
    token: tokens.wheth,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    native: false
  },
  {
    pid: 4,
    plsxPid: 7,
    lpSymbol: 'HEX-WPLS',
    lpAddress:'0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65',
    token: tokens.hex,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    native: false
  },
  {
    pid: 5,
    plsxPid: 9,
    lpSymbol: 'INC-PLSX',
    lpAddress:'0x7Dbeca4c74d01cd8782D4EF5C05C0769723fb0ea',
    token: tokens.inc,
    quoteToken: tokens.plsx,
    version: '1',
    farmActive: true,
    native: false
  }
]

export default farms
