import tokens from './tokens'

//NOTE ALL ADRESSES NEED TO BE CHECKSUMMED
const farms = [
  {
    pid: 0,
    plsxPid: 0,
    lpSymbol: 'PCAP-DAI',
    lpAddress:'0x7184872Cae460467536E693664e0EDE88b28bb86',
    token: tokens.dai,
    quoteToken: tokens.pcap,
    version: '1',
    farmActive: true,
    native: true,
  },
    {
    pid: 1,
    plsxPid: 0,
    lpSymbol: 'PCAP-STOCK',
    lpAddress:'0xFb47cB2f99a194F2C4bd5fFB548C0E1A05222e11',
    token: tokens.pcap,
    quoteToken: tokens.stock,
    version: '1',
    farmActive: true,
    native: true
  },
    {
    pid: 2,
    plsxPid: 0,
    lpSymbol: 'PCAP-pHEX',
    lpAddress:'0x1f0752711d1A95C8E1dBa461B9Bf4f06F825DA33',
    token: tokens.hex,
    quoteToken: tokens.pcap,
    version: '2',
    farmActive: true,
    native: true
  },
    {
    pid: 3,
    plsxPid: 0,
    lpSymbol: 'PCAP-INC',
    lpAddress:'0xc86D7C42D6790c305aD0a4AFe97a46475a115ada',
    token: tokens.inc,
    quoteToken: tokens.pcap,
    version: '2',
    farmActive: true,
    native: true
  },
    {
    pid: 4,
    plsxPid: 0,
    lpSymbol: 'PCAP-PLSX',
    lpAddress:'0x4cBa50530419E38454Ca34440C61E6d2c80f4990',
    token: tokens.plsx,
    quoteToken: tokens.pcap,
    version: '2',
    farmActive: true,
    native: true
  },
      {
    pid: 5,
    plsxPid: 0,
    lpSymbol: 'PCAP-PLS',
    lpAddress:'0x75a354c4FbC49f7c356D248A5dB4d90F1C271e35',
    token: tokens.wpls,
    quoteToken: tokens.pcap,
    version: '2',
    farmActive: true,
    native: true
  }
]

export default farms
